import React from 'react'
import { Helmet, Link } from '../../../plugins/gatsby-plugin-react-i18next'
import Footer from '../../components/footer'
import Header from '../../components/header'
import { dispatch } from '../../state'

const PrivacyPage = () => {
  React.useEffect(() => {
    dispatch({ type: 'setHeaderInverted', value: true })
  }, [])

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content={`t('hero.intro.1') t('hero.intro.2')`} />
        <meta name="keywords" content="gatsby, react, wapps, i18next" />
      </Helmet>
      <Header />
      <div className="max-w-2xl mx-auto px-6 pt-24">
        <div className="mb-8">
          <Link to="/" className="text-teal-500">
            Go back to Homepage
          </Link>
        </div>
        <h1 className="text-center text-3xl uppercase">Privacy Policy</h1>

        <p>
          This Privacy Policy describes how your personal information is collected, used, and shared
          when you visit playapallet.org (the “Site”).
        </p>

        <h3 className="mt-8">PERSONAL INFORMATION WE COLLECT</h3>

        <p>
          When you visit the Site, we use certain information about your device, including
          information about your web browser, and some of the cookies that are installed on your
          device to discern which language to show to you. We also save your selected language with
          your browser, so you get the same language the next time you visit.
        </p>

        <p>
          We collect Device Information using “Cookies” are data files that are placed on your
          device or computer and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
        </p>

        <p>
          When we talk about “Personal Information” in this Privacy Policy, we are talking both
          about Device Information and Personal Information.
        </p>

        <h3 className="mt-8">HOW DO WE USE YOUR PERSONAL INFORMATION?</h3>

        <p>
          We use the Information that we collect only to provide the services of the Site: The
          display of the map of pallets and the language of the content you see. Additionally, we
          use this information to communicate with you, if you reported an issue with a pallet or
          got in touch with us about other topics.
        </p>

        <h3 className="mt-8">SHARING YOUR PERSONAL INFORMATION</h3>

        <p>
          We use Google Maps to show you the locations of the pallets and to determine your
          geolocation. You can see Google Maps privacy policy here:
          https://policies.google.com/privacy?hl=en-US. You can also decide in your browser, whether
          you want to use your private geolocation or not.
        </p>

        <p>
          Finally, we may also share your Personal Information to comply with applicable laws and
          regulations, to respond to a subpoena, search warrant or other lawful request for
          information we receive, or to otherwise protect our rights.
        </p>

        <p>
          If you are a European resident, you have the right to access personal information we hold
          about you and to ask that your personal information be corrected, updated, or deleted. If
          you would like to exercise this right, please contact us through the contact information
          below.
        </p>

        <h3 className="mt-8">CHANGES</h3>

        <p>
          We may update this privacy policy from time to time in order to reflect, for example,
          changes to our practices or for other operational, legal or regulatory reasons.
        </p>

        <h3 className="mt-8">CONTACT US</h3>

        <p>
          For more information about our privacy practices, if you have questions, or if you would
          like to make a complaint, please contact us by e-mail at info@cleanoceanproject.org or by
          mail using the details provided below:
        </p>

        <p>Calle Los Quemados, Lajares, Canary Islands, 35650, Spain</p>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPage
